import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: [] };

export const levelsSlice = createSlice({
  name: "levels",
  initialState,
  reducers: {
    updateLevels: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateLevels } = levelsSlice.actions;
export default levelsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: [] };

export const wordsSlice = createSlice({
  name: "words",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.value = action.payload;
    },
    updateWord: (state, action) => {
      let word = state.value.find((o) => o.wordinLearningId === action.payload.wordinLearningId);

      if (word.levelLearningId === action.payload.levelLearningId) {
        // Nie zmienił się level -> przenieść tymczasowo o 20 pozycji do przodu

        state.value = state.value.filter((o) => o.wordinLearningId !== word.wordinLearningId);
      } else {
        word.levelLearningId = action.payload.levelLearningId;
      }
    },
  },
});

export const { updateList, updateWord } = wordsSlice.actions;
export default wordsSlice.reducer;

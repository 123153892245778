import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import "antd/dist/antd.css";
import { useSelector, useDispatch } from "react-redux";
import client from "../api/ApiClient";
import { updateWord } from "../redux/slices/wordsSlice";
import { updateUser } from "../redux/slices/userSlice";

export default function Word({ value, leftLevel, rightLevel }) {
  const [visibleTranslate, setVisibleTranslate] = useState(false);

  const user = useSelector((state) => state.user.value);
  const levels = useSelector((state) => state.levels.value);
  const dispatch = useDispatch();

  // TODO przenieść w jedno miejsce
  const getLevelIdByName = (name) => {
    return levels?.find((o) => o.name === name)?.levelId;
  };

  const updateWordToLevel = (level) => {
    let obj = {
      wordInLearningId: value.wordinLearningId,
      levelLearningId: getLevelIdByName(level),
    };
    const data = JSON.stringify(obj);

    client.post("update", data).then((response) => {
      if (response.status == 200) {
        let obj = { ...value, levelLearningId: response.data.levelLearningId };

        if (value.levelLearningId === response.data.levelLearningId) {
          // Nie zmienił się level
        }

        dispatch(updateWord(obj));
        setVisibleTranslate(false);

        const n = new Date();
        const now = new Date(n.getTime() + n.getTimezoneOffset() * 60000);
        dispatch(updateUser({ ...user, updated: now }));
      }
    });
  };

  const getNameLeft = () => {
    switch (leftLevel) {
      case "hard":
        return <>HARD</>;
      case "normal":
        return <>NORMAL</>;
      case "easy":
        return <>EASY</>;

      default:
        return <>HARD</>;
    }
  };

  const getNameRight = () => {
    switch (rightLevel) {
      case "hard":
        return <>HARD</>;
      case "normal":
        return <>NORMAL</>;
      case "easy":
        return <>EASY</>;

      default:
        return <>HARD</>;
    }
  };

  return (
    <Container>
      <BoxEn>
        <EnglishWord>{value?.english}</EnglishWord>
        <Sentence>{value?.sentence}</Sentence>
        <Transcription>{value?.transcription}</Transcription>
      </BoxEn>

      <BoxPl style={{ marginTop: "16px" }} onClick={() => setVisibleTranslate((prev) => !prev)}>
        <TranslatedWord visible={!visibleTranslate}>Show translate</TranslatedWord>
        <TranslatedWord visible={visibleTranslate}>{value?.translated}</TranslatedWord>
      </BoxPl>

      <Functions>
        <ButtonFnc type="primary" danger onClick={() => updateWordToLevel(leftLevel)}>
          {getNameLeft()}
        </ButtonFnc>
        <ButtonFnc type="primary" onClick={() => updateWordToLevel(rightLevel)}>
          {getNameRight()}
        </ButtonFnc>
      </Functions>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

const EnglishWord = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;
  text-align: center;

  font-size: 2rem;
  font-weight: bold;
  color: #4e4e4e;
`;

const Sentence = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-size: 1.2rem;
  font-weight: bold;
  color: #4e4e4e;
`;

const Transcription = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;

  text-align: center;

  font-size: 1.5rem;
  font-weight: bold;
  color: #777777;
`;

const TranslatedWord = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;

  font-size: 1rem;
  font-weight: bold;
  color: #4e4e4e;

  text-align: center;

  display: ${(props) => (props.visible ? "flex" : "none")};
`;

const BoxEn = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
`;

const BoxPl = styled.div`
  width: 100%;
  padding: 16px;

  border: 1px solid #9b9b9b;
  border-radius: 8px;

  background: #e9e9e9;

  cursor: pointer;
`;

const ButtonFnc = styled(Button)`
  width: 40%;
  height: auto;
  padding: 16px;
`;

const Functions = styled.div`
  position: fixed;
  bottom: 8px;
  width: 100%;
  max-width: 991px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

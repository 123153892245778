import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Home from "./pages/Home";
import client from "./api/ApiClient";
import { updateList } from "./redux/slices/wordsSlice";
import { updateUser } from "./redux/slices/userSlice";
import { updateLevels } from "./redux/slices/levelsSlice";
import { update } from "./redux/slices/activeWordIndexSlice";
import Loader from "./components/Loader";

class User {
  constructor(userId, name, updated) {
    this.userId = userId;
    this.name = name;
    this.updated = updated;
  }
}

class Word {
  constructor(wordinLearningId, levelLearningId, index, english, translated, transcription, sentence) {
    this.wordinLearningId = wordinLearningId;
    this.levelLearningId = levelLearningId;
    this.index = index;
    this.english = english;
    this.translated = translated;
    this.transcription = transcription;
    this.sentence = sentence;
  }
}

class Level {
  constructor(levelId, name) {
    this.levelId = levelId;
    this.name = name;
  }
}

// Funkcja przekształcająca obiekt klasy na zwykły obkiekt JS bez przypisania do klasy (konieczne aby redux działał)
const reParseObjectToPlain = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export default function Start() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const words = useSelector((state) => state.words.value);
  const levels = useSelector((state) => state.levels.value);

  useEffect(() => {
    client.get(`/${window.location.pathname.slice(1)}`).then((response) => {
      // console.log(response);

      // User
      dispatch(
        updateUser(
          reParseObjectToPlain(
            new User(response.data.user.userId, response.data.user.name, response.data.user.updated)
          )
        )
      );

      // Words
      let wordsArray = [];
      for (const w of response.data.user.wordInLearnings) {
        wordsArray.push(
          new Word(
            w.id,
            w.levelLearningId,
            w.wordRE.index,
            w.wordRE.english,
            w.wordRE.translated,
            w.wordRE.transcription,
            w.wordRE.sentence
          )
        );
      }
      dispatch(updateList(reParseObjectToPlain(wordsArray.sort((a, b) => a.index - b.index))));

      // Levels
      let levelsArray = [];
      for (const l of response.data.levels) {
        levelsArray.push(new Level(l.id, l.name));
      }
      dispatch(updateLevels(reParseObjectToPlain(levelsArray)));

      dispatch(update(0));
    });
  }, []);

  return (
    <Container>
      {user && words.length > 0 && levels.length > 0 ? <Home /> : <Loader />}
      {/* <Home /> */}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  position: relative;
  background: #fafafa;
`;

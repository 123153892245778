import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { Spin } from "antd";

export default function Loader() {
  return (
    <Container>
      <Text>REACT ENGLISH</Text>
      <Spin size="large" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  padding: 16px;
  font-weight: bold;
`;

import React, { useEffect, useState, useReducer } from "react";
import { Button, Result } from "antd";
import styled from "styled-components";
import "antd/dist/antd.css";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "antd";
import LearnNewWords from "../components/LearnNewWords";
import LearnHardWords from "../components/LearnHardWords";
import LearnEasyWords from "../components/LearnEasyWords";
import LearnNormalWords from "../components/LearnNormalWords";
import { update } from "../redux/slices/activeWordIndexSlice";

export default function Home() {
  const user = useSelector((state) => state.user.value);
  const words = useSelector((state) => state.words.value);
  const levels = useSelector((state) => state.levels.value);

  const [activeLearn, setActiveLearn] = useState("new");

  // useEffect(() => {
  //   console.log("AAAAAAA");
  // }, [words]);

  // TODO przenieść w jedno miejsce
  const getLevelIdByName = (name) => {
    return levels?.find((o) => o.name === name)?.levelId;
  };

  const renderActiveLearn = () => {
    switch (activeLearn) {
      case "new":
        return (
          <LearnNewWords
            words={words
              ?.filter((o) => o.levelLearningId === getLevelIdByName("new"))
              .sort((a, b) => a.index - b.index)}
          />
        );
      case "hard":
        return (
          <LearnHardWords
            words={words
              ?.filter((o) => o.levelLearningId === getLevelIdByName("hard"))
              .sort((a, b) => a.index - b.index)}
          />
        );
      case "normal":
        return (
          <LearnNormalWords
            words={words
              ?.filter((o) => o.levelLearningId === getLevelIdByName("normal"))
              .sort((a, b) => a.index - b.index)}
          />
        );
      case "easy":
        return (
          <LearnEasyWords
            words={words
              ?.filter((o) => o.levelLearningId === getLevelIdByName("easy"))
              .sort((a, b) => a.index - b.index)}
          />
        );
      default:
        return <LearnNewWords words={[...words].sort((a, b) => a.index - b.index)} />;
    }
  };

  const getCountInGroup = (groupName) => {
    switch (groupName) {
      case "new":
        return words?.filter((o) => o.levelLearningId === getLevelIdByName("new")).length;
      case "hard":
        return words?.filter((o) => o.levelLearningId === getLevelIdByName("hard")).length;
      case "normal":
        return words?.filter((o) => o.levelLearningId === getLevelIdByName("normal")).length;
      case "easy":
        return words?.filter((o) => o.levelLearningId === getLevelIdByName("easy")).length;
      default:
        return 0;
    }
  };

  const getElapsedTime = () => {
    if (user.updated) {
      const lastTime = new Date(user.updated).getMinutes();
      const n = new Date();
      const now = new Date(n.getTime() + n.getTimezoneOffset() * 60000).getMinutes();

      let diffTime = Math.abs(now - lastTime);

      let text = "You learned the last word:";
      let result = "";

      if (diffTime < 60) {
        result += ` ${diffTime} minutes ago`;
      } else if (diffTime < 60 * 24) {
        let hours = Math.floor(diffTime / 60);
        let minutes = diffTime % 60;
        result += ` ${hours} hours ${minutes} minutes ago`;
      } else if (diffTime >= 60 * 24) {
        let days = Math.floor(diffTime / (60 * 24));
        let hours = Math.floor((diffTime - 1440) / 60);
        let minutes = diffTime % 60;
        result += ` ${days} days ${hours} hours ${minutes} minutes ago`;
      }

      return (
        <TimeTextBox>
          <TimeText>{text}</TimeText>
          <TimeValue>{result}</TimeValue>
        </TimeTextBox>
      );
    }
  };

  return (
    <Container>
      <SubTitle>
        <span style={{ marginTop: "16px" }}>
          Hello <b>{user?.name}</b>! Enjoy learning :)
        </span>
        <TimeBox> {getElapsedTime()}</TimeBox>
      </SubTitle>
      <Menu>
        <MenuBox>
          <MenuBadge color={"geekblue"} size="default" count={getCountInGroup("new")} overflowCount={3000}>
            <MenuButton type={activeLearn === "new" ? "primary" : "default"} onClick={() => setActiveLearn("new")}>
              New words
            </MenuButton>
          </MenuBadge>
          <MenuBadge size="default" count={getCountInGroup("hard")} overflowCount={3000}>
            <MenuButton
              type={activeLearn === "hard" ? "primary" : "default"}
              onClick={() => setActiveLearn("hard")}
            >
              Hard
            </MenuButton>
          </MenuBadge>
        </MenuBox>
        <MenuBox>
          <MenuBadge color={"gold"} size="default" count={getCountInGroup("normal")} overflowCount={3000}>
            <MenuButton
              type={activeLearn === "normal" ? "primary" : "default"}
              onClick={() => setActiveLearn("normal")}
            >
              Normal
            </MenuButton>
          </MenuBadge>
          <MenuBadge color={"green"} size="default" count={getCountInGroup("easy")} overflowCount={3000}>
            <MenuButton
              type={activeLearn === "easy" ? "primary" : "default"}
              onClick={() => setActiveLearn("easy")}
            >
              Easy
            </MenuButton>
          </MenuBadge>
        </MenuBox>
      </Menu>
      <WordContainer>{renderActiveLearn()}</WordContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

const Menu = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  gap: 32px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;

  @media (max-width: 991px) {
    width: 100%;
    gap: 32px;
  }
`;

const MenuBadge = styled(Badge)`
  display: flex;
  min-width: 200px;

  @media (max-width: 991px) {
    width: 30%;
    min-width: 0;
  }
`;

const MenuButton = styled(Button)`
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const WordContainer = styled.div`
  width: 100%;
  max-width: 991px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

const TimeBox = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  color: red;
`;

const TimeTextBox = styled.div`
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`;

const TimeText = styled.div``;

const TimeValue = styled.div`
  font-weight: bold;
  margin-left: 4px;

  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

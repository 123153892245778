import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import activeWordIndexSlice from "./slices/activeWordIndexSlice";
import levelsSlice from "./slices/levelsSlice";
import userSlice from "./slices/userSlice";
import wordsSlice from "./slices/wordsSlice";

export default configureStore({
  reducer: {
    words: wordsSlice,
    user: userSlice,
    levels: levelsSlice,
    activeWordIndex: activeWordIndexSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

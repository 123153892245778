import axios from "axios";

export default axios.create({
  baseURL: "https://avi-api-ie8ww.ondigitalocean.app/api/re/",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});

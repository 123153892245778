import React, { useState } from "react";
import Word from "./Word";
import styled from "styled-components";

export default function LearnHardWords({ words }) {
  const getCard = () => {
    return words[0];
  };

  return (
    <Container>
      {words.length > 0 ? (
        <Word value={getCard()} leftLevel="hard" rightLevel="normal" />
      ) : (
        <EmptyContainer>No words</EmptyContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

const EmptyContainer = styled.div`
  width: 100%;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

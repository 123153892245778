import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: 0 };

export const activeWordIndexSlice = createSlice({
  name: "activeWordIndex",
  initialState,
  reducers: {
    next: (state) => {
      state.value = state.value + 1;
    },
    update: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { next, update } = activeWordIndexSlice.actions;
export default activeWordIndexSlice.reducer;
